.header {
  background: white;
  border-bottom: 1px solid #e5e7eb;
  padding: 0.75rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 24px;
  width: auto;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-link {
  color: #374151;
  font-size: 0.875rem;
  text-decoration: none;
  white-space: nowrap;
}

.nav-link:hover {
  color: #047857;
}

.contact-button {
  background-color: #047857;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  white-space: nowrap;
}

.contact-button:hover {
  background-color: #065f46;
}

.mobile-menu-button {
  display: none;
}

/* New styles for centered pitch deck */
.main-content {
  min-height: calc(100vh - 4rem);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem 1rem;
}

.pitch-deck-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 6rem);
}

.pitch-deck-wrapper {
  width: 90%;
  aspect-ratio: 16/9;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Rest of your existing styles */
.page-title {
  font-size: 2rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 2rem;
}

/* Form Styles */
.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition: all 0.15s ease-in-out;
}

.form-input:focus {
  outline: none;
  border-color: #047857;
  ring: 2px #047857;
}

.form-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.5rem;
}

.form-select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.form-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #047857;
  color: white;
  border-radius: 0.375rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.15s ease-in-out;
}

.form-button:hover {
  background-color: #065f46;
}

.form-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
    gap: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }

  .mobile-menu-button {
    display: block;
  }

  .pitch-deck-wrapper {
    width: 100%;
  }
}

/* Add these styles to your App.css */

.header {
  height: 4rem;  /* Fixed height for header */
  background: white;
  border-bottom: 1px solid #e5e7eb;
  padding: 0.75rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.main-content {
  padding-top: 4rem; /* Match header height */
  height: calc(100vh - 4rem);
  width: 100%;
  overflow: hidden;
}

.pdf-container {
  width: 100%;
  height: 100%;
  background: white;
}